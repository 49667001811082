/* eslint-disable @typescript-eslint/no-explicit-any */

import { ConfigOptions } from "./types/configOptions";

const store = "wrldjs";
const widgetVersion = "v324";

export default class Config {
  private _config: ConfigOptions;

  constructor(config: ConfigOptions) {
    this._config = {
      navWidgetBaseUrl: `https://cdn-webgl.wrld3d.com/${store}/addons/navigation/${widgetVersion}`,
      wrldRoutingApiUrl: "https://routing.wrld3d.com/v1/"
    };

    if (config) {
      this.assign(config);
    }
  }

  assign(config: ConfigOptions): void {
    Object.keys(config).forEach((param) => {
      this._config[param] = config[param];
    });
  }

  get(key: string): any {
    return this.has(key) ? this._config[key] : null;
  }

  has(key: string): boolean {
    return key in this._config;
  }
}

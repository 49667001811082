/* eslint-disable max-lines */

// From WrldRoutingService route step (and additional context of prev, next steps), build direction
// instructions suitable for display by NavWidget.
// Largely a line-by-line port of:
// https://github.com/wrld3d/wrld-example-app/blob/master/src/NavRouting/SdkModel/NavRouteInstructionHelper.h
// https://github.com/wrld3d/wrld-example-app/blob/master/src/NavRouting/SdkModel/NavRouteInstructionHelper.cpp
// and portions of:
// https://github.com/wrld3d/wrld-example-app/blob/master/src/NavRouting/SdkModel/NavWidgetRouteUpdateHandler.cpp
// Would benefit from refactor to provide a more rules-based, data-driven approach.

import { Route } from "../types/route";
import type wrld from "wrld.js";

type DirectionTypes = {
  destinationReached: string;
  destination: string;
  entrance: string;
  turn: string;
  continue: string;
  take: string;
  enter: string;
  exit: string;
  enterBuilding: string;
  exitBuilding: string;
  approach: string;
  endOfRoad: string;
  newName: string;
  arrive: string;
  depart: string;
  uTurn: string;
  floor: string;
  elevator: string;
  lift: string;
  startLocation: string;
  merge: string;
  fork: string;
};

type DirectionPrepositions = {
  in: string;
  onto: string;
  on: string;
  via: string;
  to: string;
  at: string;
};

type DirectionModifiers = {
  uTurn: string;
  straight: string;
  up: string;
  down: string;
  left: string;
  right: string;
  slightLeft: string;
  slightRight: string;
};

type InstructionTokens = {
  distance: string;
  floorName: string;
  nextFloorName: string;
};

type CardinalDirectionNames = string[];

type ConvertDirectionTypeRule = {
  directionType: string;
  modifier?: string;
  onlyIfIndoors?: boolean;
  onlyIfModifierNotEmpty?: boolean;
  result: string;
};

type DirectionsInstructionBuilderConfig = {
  directionTypes: DirectionTypes;
  directionPrepositions: DirectionPrepositions;
  directionModifiers: DirectionModifiers;
  instructionTokens: InstructionTokens;
  cardinalDirectionNames: CardinalDirectionNames;
  convertDirectionTypeRuleSets: ConvertDirectionTypeRule[][];
};


type RouteStep = Route.Step;

export type FormattedInstructions = {
  shortInstructionName: string;
  instructionLocation: string;
  instructionDirection: string;
  iconKey: string;
  isMultiFloor: boolean;
  nextIndoorMapFloorId: wrld.Map.MapFloorId;
};

export default class WrldDirectionsInstructionBuilder {
  private _config: DirectionsInstructionBuilderConfig;

  constructor(config?: DirectionsInstructionBuilderConfig) {
    this._config = Object.assign(WrldDirectionsInstructionBuilder.buildDefaultConfig(), config);
  }

  static buildDefaultConfig(): DirectionsInstructionBuilderConfig {

    const directionTypes: DirectionTypes = {
      destinationReached: "destination reached",
      destination: "destination",
      entrance: "entrance",
      turn: "turn",
      continue: "continue",
      take: "take",
      enter: "enter",
      exit: "exit",
      enterBuilding: "enter building",
      exitBuilding: "exit building",
      approach: "approach",
      endOfRoad: "end of road",
      newName: "new name",
      arrive: "arrive",
      depart: "depart",
      uTurn: "make a u-turn",
      floor: "floor",
      elevator: "elevator",
      lift: "lift",
      startLocation: "start location",
      merge: "merge",
      fork: "fork"
    };

    const directionPrepositions: DirectionPrepositions = {
      in: "in",
      onto: "onto",
      on: "on",
      via: "via",
      to: "to",
      at: "at"
    };

    const directionModifiers: DirectionModifiers = {
      uTurn: "uturn",
      straight: "straight",
      up: "up",
      down: "down",
      left: "left",
      right: "right",
      slightLeft: "slight left",
      slightRight: "slight right"
    };

    const instructionTokens: InstructionTokens = {
      distance: "<dist>",
      floorName: "<floor>",
      nextFloorName: "<nextfloor>"
    };

    const cardinalDirectionNames: CardinalDirectionNames = [
      "N", "NE", "E", "SE", "S", "SW", "W", "NW"
    ];

    const convertDirectionTypeRuleSets: ConvertDirectionTypeRule[][] = [
      [
        {
          directionType: directionTypes.endOfRoad,
          onlyIfIndoors: true,
          result: directionTypes.turn
        },
        {
          directionType: directionTypes.continue,
          onlyIfIndoors: true,
          result: directionTypes.turn
        },
        {
          directionType: directionTypes.fork,
          onlyIfIndoors: true,
          result: directionTypes.turn
        },
        {
          directionType: directionTypes.merge,
          onlyIfIndoors: true,
          onlyIfModifierNotEmpty: true,
          result: directionTypes.turn
        },
        {
          directionType: directionTypes.newName,
          result: directionTypes.turn
        }
      ],
      [
        {
          directionType: directionTypes.turn,
          modifier: directionModifiers.straight,
          result: directionTypes.continue
        },
        {
          directionType: directionTypes.turn,
          modifier: directionModifiers.uTurn,
          result: directionTypes.uTurn
        }
      ]
    ];

    return {
      directionTypes: directionTypes,
      directionPrepositions: directionPrepositions,
      directionModifiers: directionModifiers,
      instructionTokens: instructionTokens,
      cardinalDirectionNames: cardinalDirectionNames,
      convertDirectionTypeRuleSets: convertDirectionTypeRuleSets
    };
  }

  getInstructionTokens(): InstructionTokens {
    return this._config.instructionTokens;
  }

  getFormattedInstructionForStep(_prevStep: RouteStep, currentStep: RouteStep, nextStep: RouteStep, nextNextStep: RouteStep): FormattedInstructions {
    const directionTypes = this._config.directionTypes;
    const locationName = currentStep.name;

    const nextInstructionText = (nextStep)
      ? this._getStandardInstructionTextForStep(currentStep, nextStep, nextNextStep)
      : "";

    let shortInstructionName = this._getInstructionTextWithDistance(currentStep.distance, nextInstructionText);
    let fullInstructionText = shortInstructionName;

    let iconKey = "";
    let isMultiFloor = false;
    let nextIndoorMapFloorId = 0;

    if (!nextStep) {
      fullInstructionText = directionTypes.destinationReached;
      shortInstructionName = directionTypes.destinationReached;
      iconKey = directionTypes.arrive;
    }
    else {
      iconKey = this._getIconNameForStep(currentStep, nextStep, nextNextStep);

      if (nextStep.isMultiFloor) {
        isMultiFloor = true;
        nextIndoorMapFloorId = (nextNextStep) ? nextNextStep.indoorMapFloorId : nextStep.indoorMapFloorId;
      }
      else if (currentStep.isMultiFloor) {
        isMultiFloor = true;
        nextIndoorMapFloorId = nextStep.indoorMapFloorId;
      }
    }

    return this._makeNavRouteFormattedInstructions(shortInstructionName,
      locationName,
      fullInstructionText,
      iconKey,
      isMultiFloor,
      nextIndoorMapFloorId
    );
  }

  getFormattedInstructionForStart(step: RouteStep): FormattedInstructions {
    const isMultiFloor = false;
    const directionTypes = this._config.directionTypes;
    return this._makeNavRouteFormattedInstructions(directionTypes.startLocation,
      step.name,
      directionTypes.startLocation,
      directionTypes.depart,
      isMultiFloor,
      step.indoorMapFloorId);
  }

  _makeNavRouteFormattedInstructions(
    shortInstructionName: string,
    instructionLocation: string,
    instructionDirection: string,
    iconKey: string,
    isMultiFloor: boolean,
    nextIndoorMapFloorId: number
  ): FormattedInstructions {
    return {
      shortInstructionName: String(shortInstructionName),
      instructionLocation: String(instructionLocation),
      instructionDirection: String(instructionDirection),
      iconKey: String(iconKey),
      isMultiFloor: isMultiFloor,
      nextIndoorMapFloorId: Number(nextIndoorMapFloorId)
    };
  }

  _getDirectionWithOptionalLocation(direction: string, includeLocation: boolean, verb: string, locationName: string): string {
    if (!locationName || !includeLocation) {
      return direction;
    }
    return direction + " " + verb + " " + locationName;
  }

  _getHumanReadableBearing(bearing: number): string {
    const cardinalDirectionNames = this._config.cardinalDirectionNames;
    const div = (360.0 / (cardinalDirectionNames.length * 2.0));
    const dirIndex = Math.floor((bearing - div) / (div * 2)) % (cardinalDirectionNames.length);
    return cardinalDirectionNames[dirIndex];
  }

  _getInstructionForMultifloorStep(step: RouteStep, _nextStep: RouteStep): string {
    const directionTypes = this._config.directionTypes;
    const direction = directionTypes.take + " " + step.directions.type;
    const floorName = directionTypes.floor + " " + this._config.instructionTokens.nextFloorName;
    return this._getDirectionWithOptionalLocation(direction, true, this._config.directionPrepositions.to, floorName);
  }

  _getInstructionForEnterExitBuilding(step: RouteStep, nextStep: RouteStep): string {
    const directionTypes = this._config.directionTypes;
    return this._getDirectionWithOptionalLocation(
      nextStep.isIndoors ? directionTypes.enterBuilding : directionTypes.exitBuilding,
      !nextStep.isIndoors,
      this._config.directionPrepositions.via,
      step.name);
  }

  _getInstructionForDeparting(step: RouteStep): string {
    const direction = this._config.directionTypes.depart + " " + this._getHumanReadableBearing(step.directions.bearingAfter);
    return this._getDirectionWithOptionalLocation(direction, !step.isIndoors, this._config.directionPrepositions.on, step.name);
  }

  _getInstructionForApproachingEntrance(step: RouteStep): string {
    const directionTypes = this._config.directionTypes;
    const direction = directionTypes.approach + " " + directionTypes.entrance;
    return this._getDirectionWithOptionalLocation(direction, !step.isIndoors, this._config.directionPrepositions.on, step.directions.modifier);
  }

  _getBasicInstruction(type: string, modifier: string, locationName: string, verb: string, includeLocation: boolean): string {
    let direction = type;
    if (modifier) {
      direction += " " + modifier;
    }

    return this._getDirectionWithOptionalLocation(direction,
      includeLocation,
      verb,
      locationName);
  }

  _convertDirectionType(type: string, modifier: string, isIndoors: boolean): string {
    const directionTypes = this._config.directionTypes;
    const directionModifiers = this._config.directionModifiers;
    let currentType = type;

    if (isIndoors) {
      if (currentType === directionTypes.endOfRoad
        || type === directionTypes.continue
        || type === directionTypes.fork
        || (type === directionTypes.merge && modifier)) {
        currentType = directionTypes.turn;
      }
    }

    if (type === directionTypes.newName) {
      currentType = directionTypes.turn;
    }

    if (currentType === directionTypes.turn) {
      if (modifier === directionModifiers.straight) {
        return directionTypes.continue;
      }
      else if (modifier === directionModifiers.uTurn) {
        return directionTypes.uTurn;
      }
    }

    return currentType;
  }

  _convertDirectionModifier(_type: string, modifier: string, isIndoors: boolean): string {
    const directionModifiers = this._config.directionModifiers;
    if (!isIndoors) {
      return modifier;
    }
    if (modifier === directionModifiers.slightLeft) {
      return directionModifiers.left;
    }
    if (modifier === directionModifiers.slightRight) {
      return directionModifiers.right;
    }

    return modifier;
  }

  _getInstructionTextWithDistance(distance: number, nextInstructionText: string): string {
    if (distance < 1) {
      return nextInstructionText;
    }
    return this._config.directionPrepositions.in + " " + this._config.instructionTokens.distance + "m " + nextInstructionText;
  }

  _getStandardInstructionTextForStep(prevStep: RouteStep, step: RouteStep, nextStep: RouteStep): string {
    const directionTypes = this._config.directionTypes;
    const directionPrepositions = this._config.directionPrepositions;

    let type = step.directions.type;
    let modifier = this._convertDirectionModifier(type, step.directions.modifier, step.isIndoors);

    if (step.isMultiFloor && nextStep) {
      return this._getInstructionForMultifloorStep(step, nextStep);
    }

    if (prevStep && prevStep.isMultiFloor) {
      type = directionTypes.exit;
      modifier = prevStep.directions.type;
    }

    if (type === directionTypes.entrance) {
      // NOTE: Handling a case from route service where we have 2 steps marked 'entrance'
      if (nextStep && nextStep.directions.type !== directionTypes.entrance) {
        return this._getInstructionForEnterExitBuilding(step, nextStep);
      }

      type = directionTypes.turn;
    }

    if (type === directionTypes.arrive) {
      if (nextStep) {
        return this._getInstructionForApproachingEntrance(step);
      }
      else {
        const includeLocation = true;
        return this._getBasicInstruction(
          type,
          "",
          directionTypes.destination,
          directionPrepositions.at,
          includeLocation
        );
      }
    }
    else if (type === directionTypes.depart) {
      return this._getInstructionForDeparting(step);
    }

    type = this._convertDirectionType(type, modifier, step.isIndoors);

    const shouldShowLocationName = (!step.isIndoors) || (prevStep.name !== step.name);
    return this._getBasicInstruction(
      type,
      modifier,
      shouldShowLocationName ? step.name : "",
      directionPrepositions.onto,
      !step.isIndoors);
  }

  _getIconNameForStep(prevStep: RouteStep, step: RouteStep, nextStep: RouteStep): string {
    let type = step.directions.type;
    let modifier = step.directions.modifier;

    const directionTypes = this._config.directionTypes;
    const directionModifiers = this._config.directionModifiers;


    if (type === directionTypes.newName ||
      type === directionTypes.continue ||
      type === directionTypes.arrive) {
      type = directionTypes.turn;
      if (!modifier) {
        modifier = directionModifiers.straight;
      }
    }

    const isMultiLevel = (step.isMultiFloor && prevStep);
    const isGoingUp = (isMultiLevel && nextStep && (nextStep.indoorMapFloorId > prevStep.indoorMapFloorId));

    if (isMultiLevel) {
      if (type === directionTypes.elevator) {
        return directionTypes.lift;
      }
      else {
        modifier = isGoingUp ? directionModifiers.up : directionModifiers.down;
      }
    }

    if (type === directionTypes.entrance) {
      if (nextStep && nextStep.directions.type !== directionTypes.entrance) {
        const isEnteringBuilding = nextStep.isIndoors;
        return isEnteringBuilding ? directionTypes.enter : directionTypes.exit;
      }
      type = directionTypes.turn;
    }

    type = type.split(" ").join("_");
    modifier = modifier.split(" ").join("_");

    if ((type === directionTypes.arrive) || (type === directionTypes.depart)) {
      return type;
    }

    if ((type === directionTypes.turn) && (modifier === directionModifiers.straight)) {
      return modifier;
    }

    return [type, modifier].join("_");
  }

  // todo - ensure equivalency of below vs _convertDirectionType
  // Can this be scrapped?
  _convertDirectionType_ALTERNATE(type: string, modifier: string, isIndoors: string): string {

    const shouldApplyRule = (rule, params): boolean => {
      if (params.type !== rule.directionType) {
        return false;
      }
      if ("onlyIfIndoors" in rule && !params.isIndoors) {
        return false;
      }
      const modifierEmpty = params.modifier?.length === 0;
      if ("onlyIfModifierNotEmpty" in rule && modifierEmpty) {
        return false;
      }
      if ("modifier" in rule && (params.modifier !== rule.modifier)) {
        return false;
      }
      return true;
    };

    const convertForRuleSet = (params, ruleSet: ConvertDirectionTypeRule[], predicate: (rule, params) => boolean) => {
      const rule = ruleSet.find((rule) => {
        return predicate(rule, params);
      });
      return rule ? rule.result : params.type;
    };

    const convert = (params): string => {
      let convertedType = params.type;
      this._config.convertDirectionTypeRuleSets.forEach((ruleSet) => {
        params = Object.assign(params, {type: convertedType});
        convertedType = convertForRuleSet(params, ruleSet, shouldApplyRule);
      });
      return convertedType;
    };

    const result = convert({type: type, modifier: modifier, isIndoors: isIndoors});

    return result;
  }
}

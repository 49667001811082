import React from "react";

export default class LocationInputBox extends React.Component {

  constructor(props) {
    super(props);
  }

  _getLocationIcon(isStartLocation) {
    const locationIconClassName = "location-icon " + (isStartLocation ? "start" : "end");
    return <div className={locationIconClassName}/>;
  }

  render() {
    const {
      inputId,
      value,
      placeholder,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      onClearClick,
      isStartLocation
    } = this.props;

    const clearIconClassName = "clear-icon";

    return (
      <div className="nav-location-container">
        {this._getLocationIcon(isStartLocation)}
        <input
          className="text-field"
          id={inputId}
          type="text"
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={!onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
        <button className={clearIconClassName} onClick={onClearClick}></button>
      </div>
    );
  }
}
/* eslint-disable react/prefer-stateless-function */
import React from "react";

import LocationInputBox from "./LocationInputBox.jsx";

export default class SetupJourneyPanel extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      startLocationName,
      endLocationName,
      onStartLocationFocus,
      onEndLocationFocus,
      onStartLocationClearClick,
      onEndLocationClearClick,
      onTopPanelBackClick,
      onSwitchFieldsClick,
      isContentVisible
    } = this.props;

    const contentClassName = "div-centre" + (isContentVisible ? " visible" : " hidden");

    return (
      <div className="wrld-setup-journey-panel">
        <div className="div-left">
          <button className="back-button" onClick={onTopPanelBackClick}></button>
        </div>
        <div className={contentClassName}>
          <LocationInputBox
            inputId="journey-start-location-input"
            value={startLocationName}
            placeholder={""}
            onClearClick={onStartLocationClearClick}
            onFocus={onStartLocationFocus}
            //onChange={onInputChange}
            // onKeyDown={this._onKeyDown}
            isStartLocation={true}
          >
          </LocationInputBox>
          <LocationInputBox
            inputId="journey-end-location-input"
            value={endLocationName}
            placeholder={""}
            onClearClick={onEndLocationClearClick}
            onFocus={onEndLocationFocus}
            isStartLocation={false}
          >
          </LocationInputBox>
        </div>
        <div className="div-right">
          <button className="switch-fields-button" onClick={onSwitchFieldsClick}></button>
        </div>
      </div>
    );
  }
}

// https://github.com/wrld3d/wrld-routing-api

import { Location } from "../types/location";

const defaultTravelMode = "walking";
const routeEndpoint = "route?";

export type RouteQueryOptions = {
  travelMode?: string;
  locations: Location[];
};

export default class WrldRoutingApi {
  private _apiKey: string;
  private _urlRoot: string;

  constructor(apiKey: string, urlRoot: string) {
    this._apiKey = apiKey;
    this._urlRoot = urlRoot;
  }

  buildRouteQuery(options: RouteQueryOptions): string {
    try {
      const travelMode = options.travelMode || defaultTravelMode;
      const lngLats = [];
      options.locations.forEach(location => {
        const lngLat = [location.latLng.lng, location.latLng.lat];
        if (location.indoorMapId) {
          lngLat.push(location.indoorMapFloorId);
        }
        lngLats.push(lngLat.join(","));
      });

      const urlString = this._urlRoot + routeEndpoint +
        "loc=" + lngLats.join("%3B") +
        "&travelmode=" + travelMode +
        "&apikey=" + this._apiKey;
      return urlString;
    }
    catch(e) {
      throw "Error building url for options: " + JSON.stringify(options);
    }
  }
}

/* eslint-disable react/prefer-stateless-function */
import React from "react";

const icon_prefix = "small_";
const icon_suffix = ".svg";

export default class DirectionRow extends React.Component {

  render() {
    const {
      onSelect,
      direction,
      baseUrl,
      isHighlighted
    } = this.props;

    const iconUrl = baseUrl + "/resources/" + icon_prefix + direction.icon + icon_suffix;
    const iconStyle = {
      backgroundImage: "url(" + iconUrl + ")"
    };
    const rowClassName = "direction-row" + (isHighlighted ? " highlighted" : "");
    return (
      <div className={rowClassName} onClick={onSelect}>
        <div className="direction-icon" style={iconStyle}/>
        <div className="direction-name">{direction.name}</div>
      </div>
    );
  }
}
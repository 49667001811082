import { Direction } from "../types/direction";
import { Route } from "../types/route";
import WrldDirectionsService, { BuildDirectionsCallback } from "./WrldDirectionsService";

export default class DirectionsServiceFacade {
  private _internalDirectionsService: WrldDirectionsService;

  constructor(internalDirectionsService: WrldDirectionsService) {
    this.initialize(internalDirectionsService);
  }

  initialize(internalDirectionsService: WrldDirectionsService): void {
    this._internalDirectionsService = internalDirectionsService;
  }

  buildDirections(route: Route, callback: BuildDirectionsCallback): void {
    if (this._internalDirectionsService && typeof this._internalDirectionsService.buildDirections === "function") {
      this._internalDirectionsService.buildDirections(route, callback);
    }
  }

  replaceFormatTokens(directions: Direction[]): Direction[] {
    if (this._internalDirectionsService && typeof this._internalDirectionsService.replaceFormatTokens === "function") {
      return this._internalDirectionsService.replaceFormatTokens(directions);
    }
    return directions;
  }

}

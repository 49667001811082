/* eslint-disable react/prefer-stateless-function */
import React from "react";

export default class TimeToDestinationPanel extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      routeDurationMinutes,
      routeDurationSeconds
    } = this.props;

    const className = "time-to-destination-panel";
    const minutesLabelText = routeDurationMinutes === 1 ? "min" : "mins";
    const secondsLabelText = routeDurationSeconds === 1 ? "sec" : "secs";

    return (
      <div className={className}>
        <div className="time-to-destination-panel-flex-container-outer">
          <div className="time-to-destination-panel-mins-number">{routeDurationMinutes}</div>
          <div className="time-to-destination-panel-flex-container-inner">
            <div className="time-to-destination-panel-mins-label">{minutesLabelText }</div>
            <div>
              <div className="time-to-destination-panel-secs-number">{routeDurationSeconds}</div>
              <div className="time-to-destination-panel-secs-label"> {secondsLabelText}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

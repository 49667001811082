/* eslint-disable max-lines */
import type wrld from "wrld.js";

import WrldRoutingApi, { RouteQueryOptions } from "./WrldRoutingApi";

import { Route } from "../types/route";

type GeometryJson = {
  coordinates: number[];
  type: string;
};

type DirectionsJson = {
  bearing_after: number;
  bearing_before: number;
  location: number[];
  modifier: string;
  type: string;
};

type StepsJson = {
  building_id: string;
  distance: number;
  duration: number;
  geometry: GeometryJson;
  intersections: {
    bearings: number[];
    entry: boolean[];
    in: number;
    location: number[];
  }[];
  maneuver: DirectionsJson;
  mode: string;
  name: string;
};

type RouteJson = {
  distance: number;
  duration: number;
  geometry: GeometryJson;
  legs: {
    annotation: {
    datasources: number[];
    nodes: number[];
    duration: number[];
    distance: number[];
    }
    distance: number;
    duration: number;
    steps: StepsJson[]
    summary: string;
  }[];
};

type RoutesJson = {
  routes: RouteJson[];
  waypoints: {
    hint: string;
    location: number[];
    name: string;
  }[];
};

type RouteMetaData = {
  indoorMapId: wrld.Map.MapId;
  indoorMapFloorId: wrld.Map.MapFloorId;
  name: string;
  isIndoors: boolean;
  isMultiFloor: boolean;
};

export type FindRouteCallback = ({ error, route }: {
  error?: string;
  route?: Route;
}) => void;

export default class WrldRoutingService {
  private _wrldRoutingApi: WrldRoutingApi;

  constructor(apiKey: string, urlRoot: string) {
    this._wrldRoutingApi = new WrldRoutingApi(apiKey, urlRoot);
  }

  findRoute(routingQueryOptions: RouteQueryOptions, callback: FindRouteCallback): void {
    const url = this._wrldRoutingApi.buildRouteQuery(routingQueryOptions);
    fetch(url)
      .then(response => {
        if (response.status === 200) return response.json();
        return null;
      })
      .then(resultJson => {
        if (resultJson.code === "Error") {
          // Some errors are returned with HTTP code 200, but with a code of "Error"
          // See https://github.com/wrld3d/routing-service/blob/d1917f7ad912e89ee2b2ba93694eb8d8b31970e3/web/osrm-wrapper.rb#L58
          callback({ error: resultJson.error });
        } else {
          const results: Route = resultJson ? this._formatResults(resultJson) : null;
          callback({ route: results });
        }
      })
      .catch((err) => {
        callback({ error: err });
      });
  }

  _parseGeometry(geometryJson: GeometryJson): L.LatLng[] {
    const path: L.LatLng[] = [];
    geometryJson.coordinates.forEach(coord => {
      path.push(L.latLng(coord[1], coord[0]));
    });
    return path;
  }

  _parseDirections(directionJson: DirectionsJson, directionTypeOverride: string): Route.Step.Directions {
    return {
      location: L.latLng(directionJson.location[1], directionJson.location[0]),
      type: directionTypeOverride ? directionTypeOverride : directionJson.type,
      modifier: directionJson.modifier ?? "",
      bearingBefore: directionJson.bearing_before,
      bearingAfter: directionJson.bearing_after
    };
  }

  _parseMetadataTag(metadataJson: string, tag: string): string {
    const decoratedTag = "{" + tag + ":";
    const occurrence = metadataJson.indexOf(decoratedTag);
    if (occurrence !== -1) {
      const postTag = metadataJson.slice(occurrence + decoratedTag.length);
      const nextBracketIndex = postTag.indexOf("}");
      if (nextBracketIndex !== -1) {
        return postTag.substring(0, nextBracketIndex);
      }
    }
    return null;
  }

  _parseMetadata(nameJson: string): RouteMetaData {
    const indoorMapId = this._parseMetadataTag(nameJson, "bid");
    const isIndoors = indoorMapId?.length > 0;
    const level = this._parseMetadataTag(nameJson, "level");
    const isMultiFloor = (level === "multiple");
    const indoorMapFloorId = isMultiFloor ? 0 : Number(level);
    return {
      indoorMapId: indoorMapId ? indoorMapId : "",
      indoorMapFloorId: indoorMapFloorId,
      name: isIndoors ? this._parseMetadataTag(nameJson, "name") : nameJson,
      isIndoors: isIndoors,
      isMultiFloor: isMultiFloor
    };
  }

  _parseRouteSteps(stepsJsons: StepsJson[]): Route.Step[] {
    const steps = [];
    stepsJsons.forEach(stepJson => {
      const metadata = this._parseMetadata(stepJson.name);
      const metadataType = this._parseMetadataTag(stepJson.name, "type");
      const directionTypeOverride = (metadata.indoorMapId && metadataType !== "pathway") ? metadataType : null;
      const step = Object.assign({}, metadata, {
        path: this._parseGeometry(stepJson.geometry),
        mode: stepJson.mode,
        directions: this._parseDirections(stepJson.maneuver, directionTypeOverride),
        duration: stepJson.duration,
        distance: stepJson.distance
      });
      steps.push(step);
    });

    return steps;
  }

  _parseRouteSection(routesJson: RoutesJson): Route.Section {
    let steps = [];
    const routes = routesJson.routes;
    const bestRouteJson = routes[0];
    const legsJson = bestRouteJson.legs;
    legsJson.forEach(legJson => {
      const stepsJson = legJson.steps;
      const routeSteps = this._parseRouteSteps(stepsJson);
      steps = steps.concat(routeSteps);
    });
    let duration = 0.0;
    let distance = 0.0;
    steps.forEach(step => {
      duration += step.duration;
      distance += step.distance;
    });
    return {
      steps: steps,
      duration: duration,
      distance: distance
    };
  }

  _formatResults(resultJson: {
    routes: RoutesJson[];
    type: string;
  } | RoutesJson): Route {
    const routeSections = [];
    if (("type" in resultJson) && (resultJson.type === "multipart")) {
      const multiroute = resultJson.routes;
      multiroute.forEach(routesJson => {
        const routeSection = this._parseRouteSection(routesJson);
        routeSections.push(routeSection);
      });
    }
    else {
      const routeSection = this._parseRouteSection(resultJson as RoutesJson);
      routeSections.push(routeSection);
    }

    let routeDuration = 0.0;
    let routeDistance = 0.0;
    routeSections.forEach(routeSection => {
      routeDuration += routeSection.duration;
      routeDistance += routeSection.distance;
    });

    return {
      sections: routeSections,
      routeDuration: routeDuration,
      routeDistance: routeDistance
    };
  }
}

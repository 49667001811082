/* eslint-disable react/prefer-stateless-function */
import React from "react";

import DirectionRow from "../components/DirectionRow.jsx";

export default class DirectionsPanel extends React.Component {

  constructor(props) {
    super(props);
  }

  _getResultElements() {
    const {
      directions,
      onResultSelect,
      highlightIndex,
      baseUrl
    } = this.props;

    const resultElements = [];
    directions.forEach((direction, rowIndex) => {
      resultElements.push(
        <DirectionRow
          key={rowIndex}
          onSelect={() => onResultSelect(direction, rowIndex)}
          direction={direction}
          baseUrl={baseUrl}
          isHighlighted={highlightIndex === rowIndex}
        />
      );
    });

    return resultElements;
  }

  render() {
    const className = "directions-panel";
    const resultElements = this._getResultElements();

    return (
      <div className={className}>
        {resultElements}
      </div>
    );
  }
}

import WrldRoutingService, { FindRouteCallback } from "./WrldRoutingService";
import { RouteQueryOptions } from "./WrldRoutingApi";

export default class RoutingServiceFacade {
  private _internalRoutingService: WrldRoutingService;

  constructor(internalRoutingService: WrldRoutingService) {
    this.initialize(internalRoutingService);
  }

  initialize(internalRoutingService: WrldRoutingService): void {
    this._internalRoutingService = internalRoutingService;
  }

  findRoute(routingQueryOptions: RouteQueryOptions, callback: FindRouteCallback): void {
    if (this._internalRoutingService && typeof this._internalRoutingService.findRoute === "function") {
      this._internalRoutingService.findRoute(routingQueryOptions, callback);
    }
  }

}

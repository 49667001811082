/* eslint-disable @typescript-eslint/no-explicit-any */

import Evented from "../../common/js/Evented";
import type wrld from "wrld.js";
import React from "react";
import { render } from "react-dom";

import Config from "./Config";

import NavWidgetContainer from "./navwidget/view/containers/NavWidgetContainer";
import RoutingServiceFacade from "./services/RoutingServiceFacade";
import WrldRoutingService, { FindRouteCallback } from "./services/WrldRoutingService";
import DirectionsServiceFacade from "./services/DirectionsServiceFacade";
import WrldDirectionsService, { WrldDirectionsServiceConfig, BuildDirectionsCallback } from "./services/WrldDirectionsService";
import { Location } from "./types/location";
import { Direction } from "./types/direction";
import { Route } from "./types/route";
import { ConfigOptions } from "./types/configOptions";

class WrldNavigation extends Evented {
  private _apiKey: string;
  private _id: string;
  private _shouldRenderWidget: boolean;
  private _reactNavWidget: any;
  private _map: wrld.Map;
  private _config: Config;
  private _routingService: RoutingServiceFacade;
  private _directionsService: DirectionsServiceFacade;
  private _currentDirections: Direction[];

  constructor(id: string, map: wrld.Map, apiKey: string, options: ConfigOptions = {}) {
    super();
    this._apiKey = apiKey;
    this._id = id;
    this._shouldRenderWidget = (typeof this._id === "string" && this._id.length > 0);
    this._reactNavWidget = null;
    this._map = map;

    this._config = new Config(options);
    this._routingService = new RoutingServiceFacade(null);
    this._directionsService = new DirectionsServiceFacade(null);

    this._currentDirections = null;

    this._onIndoorMapLoad = this._onIndoorMapLoad.bind(this);

    this.assignConfig(options);
  }

  private static _createDefaultRoutingService(apiKey: string, config: Config): WrldRoutingService {
    const urlRoot = config.get("wrldRoutingApiUrl");
    return new WrldRoutingService(apiKey, urlRoot);
  }

  private static _createDefaultDirectionsService(wrldDirectionsServiceConfig: WrldDirectionsServiceConfig): WrldDirectionsService {
    return new WrldDirectionsService(wrldDirectionsServiceConfig);
  }


  static buildLocation(locationName: string, latitude: number, longitude: number, indoorMapId: wrld.Map.MapId, indoorMapFloorId: wrld.Map.MapFloorId): Location {
    return {
      name: locationName || "",
      latLng: L.latLng(latitude, longitude),
      isIndoors: indoorMapId?.length > 0,
      indoorMapId: indoorMapId || "",
      indoorMapFloorId: indoorMapFloorId || 0
    };
  }

  assignConfig(config: ConfigOptions): void {
    if (config) {
      this._config.assign(config);
    }
    this._refreshServices();
    this._render();
  }

  openControl(): void {
    this._reactNavWidget.openControl();
  }

  closeControl(): void {
    this._reactNavWidget.closeControl();
  }

  resetControl(): void {
    this._currentDirections = null;
    this._reactNavWidget.resetControl();
  }

  swapJourneyLocations(): void {
    this._reactNavWidget.swapJourneyLocations();
  }

  setDirections(directions: Direction[]): void {
    this._currentDirections = directions;
    this._reactNavWidget.setDirections(directions);
  }

  clearDirections(): void {
    this._currentDirections = null;
    this._reactNavWidget.clearDirections();
  }

  setRouteDuration(routeDurationSeconds: number): void {
    this._reactNavWidget.setRouteDuration(routeDurationSeconds);
  }

  clearLocations(): void {
    this._reactNavWidget.clearLocations();
  }

  setStartLocation(location: Location): void {
    this._reactNavWidget.setStartLocation(location);
  }

  setEndLocation(location: Location): void {
    this._reactNavWidget.setEndLocation(location);
  }

  findRoute(startLocation: Location, endLocation: Location, callback: FindRouteCallback): void {
    const options = { locations: [startLocation, endLocation] };
    this._routingService.findRoute(options, callback);
  }

  buildDirectionsForRoute(route: Route, callback: BuildDirectionsCallback): void {
    this._directionsService.buildDirections(route, callback);
  }

  private _getIndoorMapFloorShortName(indoorMapId: wrld.Map.MapId, indoorMapFloorId: wrld.Map.MapFloorId): string {
    return this._map.indoors.tryGetFloorShortName(indoorMapId, indoorMapFloorId);
  }

  private _onIndoorMapLoad(): void {
    if (this._currentDirections !== null) {
      const updatedDirections = this._directionsService.replaceFormatTokens(this._currentDirections);
      this.setDirections(updatedDirections);
    }
  }

  private _refreshServices(): void {
    const routingService = WrldNavigation._createDefaultRoutingService(this._apiKey, this._config);

    this._routingService.initialize(routingService);

    const wrldDirectionsServiceConfig = {
      getReadableFloorName: (indoorMapId, indoorMapFloorId) => {
        return this._getIndoorMapFloorShortName(indoorMapId, indoorMapFloorId);
      }
    };

    const directionsService = WrldNavigation._createDefaultDirectionsService(wrldDirectionsServiceConfig);

    this._directionsService.initialize(directionsService);

    this._map.indoors.off("indoormapload", this._onIndoorMapLoad);
    this._map.indoors.on("indoormapload", this._onIndoorMapLoad);
  }

  private _render(): void {
    if (!this._shouldRenderWidget) return;
    this._reactNavWidget = render(
      <NavWidgetContainer
        config={this._config}
        onStartLocationFocussedCallback={() => { this.fire("startlocationfocused"); }}
        onEndLocationFocussedCallback={() => { this.fire("endlocationfocused"); }}
        onStartLocationClearedCallback={() => { this.fire("startlocationclear"); }}
        onEndLocationClearedCallback={() => { this.fire("endlocationclear"); }}
        onSwapJourneyLocationsCallback={() => { this.fire("swapjourneylocations"); }}
        onBackSelectedCallback={() => { this.fire("backselected"); }}
        onDirectionResultSelectedCallback={(event) => { this.fire("directionresultselected", event); }}
      />,
      document.getElementById(this._id)
    );
  }
}

const wrldNavigation = (id: string, map: wrld.Map, apiKey: string, options?: ConfigOptions): WrldNavigation => {
  return new WrldNavigation(id, map, apiKey, options);
};

export {
  WrldNavigation,
  wrldNavigation
};

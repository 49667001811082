/* eslint-disable max-lines */

import type wrld from "wrld.js";
import { Route } from "../types/route";
import { Direction } from "../types/direction";

import WrldDirectionsInstructionBuilder, { FormattedInstructions } from "./WrldDirectionsInstructionBuilder";

export type WrldDirectionsServiceConfig = {
  replaceFormatTokenFor?: {
    distance: boolean;
    floorName: boolean;
    nextFloorName: boolean;
  };
  getReadableFloorName?: (indoorMapId: wrld.Map.MapId, indoorMapFloorId: wrld.Map.MapFloorId) => string;
};

type DirectionsForRoute = {
  directions: Direction[];
  duration: number;
  distance: number;
  route: Route;
};

export type BuildDirectionsCallback = (result: {
  error?: string;
  directions?: Direction[];
  duration?: number;
  distance?: number;
  route?: Route;
}) => void;

type RouteStep = Route.Step;

type FormatTokenToReplacement = { [key: string]: string; };

// Transform a route result (provided by WrldRoutingService) into directions suitable for display by
// NavWidget.
export default class WrldDirectionsService {
  private _config: WrldDirectionsServiceConfig;
  private _instructionBuilder: WrldDirectionsInstructionBuilder;

  constructor(config: WrldDirectionsServiceConfig) {
    this._config = Object.assign(WrldDirectionsService.buildDefaultConfig(), config);
    this._instructionBuilder = new WrldDirectionsInstructionBuilder();
  }

  static buildDefaultConfig(): WrldDirectionsServiceConfig {
    return {
      replaceFormatTokenFor: {
        distance: true,
        floorName: true,
        nextFloorName: true
      },
      getReadableFloorName: null
    };
  }

  buildDirections(route: Route, callback: BuildDirectionsCallback): void {
    if (!callback) {
      return;
    }

    try {
      const directions = this._buildDirections(route);
      callback(directions);
    }
    catch(err) {
      callback({ error: err });
    }
  }

  replaceFormatTokens(directions: Direction[]): Direction[] {
    const updatedDirections: Direction[] = [];
    directions.forEach(direction => {
      const updatedDirection = this._replaceFormatTokens(direction);
      updatedDirections.push(updatedDirection);
    });

    return updatedDirections;
  }

  _buildFormatTokenToReplacement(direction: Direction): FormatTokenToReplacement {
    const formatTokenToReplacement: FormatTokenToReplacement = {};

    const instructionTokens = this._instructionBuilder.getInstructionTokens();

    if (direction.distance !== undefined && direction.distance !== null) {
      if (this._config.replaceFormatTokenFor.distance) {
        formatTokenToReplacement[instructionTokens.distance] = String(direction.distance);
      }
    }

    if (direction.indoorMapId !== undefined && direction.indoorMapId !== null) {
      if (direction.indoorMapFloorId !== undefined) {
        const floorName = this._getReadableFloorName(direction.indoorMapId, direction.indoorMapFloorId);
        if (this._config.replaceFormatTokenFor.floorName && floorName) {
          formatTokenToReplacement[instructionTokens.floorName] = floorName;
        }
      }

      if (direction.nextIndoorMapFloorId !== undefined) {
        const nextFloorName = this._getReadableFloorName(direction.indoorMapId, direction.nextIndoorMapFloorId);
        if (this._config.replaceFormatTokenFor.nextFloorName && nextFloorName) {
          formatTokenToReplacement[instructionTokens.nextFloorName] = nextFloorName;
        }
      }
    }

    return formatTokenToReplacement;
  }

  _replaceFormatTokens(direction: Direction): Direction {
    if (!direction.isUsingPlaceHolder) {
      return direction;
    }

    const formatTokenToReplacement = this._buildFormatTokenToReplacement(direction);

    const name = (direction.name) ? this._getReadableString(direction.name, formatTokenToReplacement) : null;
    const instruction = (direction.instruction) ? this._getReadableString(direction.instruction, formatTokenToReplacement) : null;
    const nextInstruction = (direction.nextInstruction) ? this._getReadableString(direction.nextInstruction, formatTokenToReplacement) : null;

    const instructionTokens = this._instructionBuilder.getInstructionTokens();

    const updatedDirection = Object.assign(direction);
    let hasFormatTokensRemaining = false;

    if (name !== null) {
      updatedDirection.name = name;
      hasFormatTokensRemaining = hasFormatTokensRemaining || this._containsFormatToken(name, instructionTokens);
    }

    if (instruction !== null) {
      updatedDirection.instruction = instruction;
      hasFormatTokensRemaining = hasFormatTokensRemaining || this._containsFormatToken(instruction, instructionTokens);
    }

    if (nextInstruction !== null) {
      updatedDirection.nextInstruction = nextInstruction;
      hasFormatTokensRemaining = hasFormatTokensRemaining || this._containsFormatToken(nextInstruction, instructionTokens);
    }

    updatedDirection.isUsingPlaceHolder = hasFormatTokensRemaining;

    return updatedDirection;
  }



  // see https://github.com/wrld3d/wrld-example-app/blob/master/src/NavRouting/SdkModel/NavWidgetRouteUpdateHandler.cpp
  _buildDirections(route: Route): DirectionsForRoute {
    const directions: Direction[] = [];

    const steps = this._buildFlattenedSteps(route.sections);

    if (steps.length > 0) {
      const directionForFirstStep = this._buildDirectionsForStart(steps[0]);
      directions.push(directionForFirstStep);
    }

    steps.forEach((step, stepIndex) => {
      const prevStep = (stepIndex > 0) ? steps[stepIndex - 1] : null;
      const nextStep = ((stepIndex + 1) < steps.length) ? steps[stepIndex + 1] : null;
      const nextNextStep = ((stepIndex + 2) < steps.length) ? steps[stepIndex + 2] : null;
      const directionForStep = this._buildDirectionsForRouteStep(prevStep, step, nextStep, nextNextStep);
      directions.push(directionForStep);
    });

    return {
      directions: directions,
      duration: Math.round(route.routeDuration),
      distance: Math.round(route.routeDistance),
      route: route
    };
  }

  _buildFlattenedSteps(sections: Route.Section[]): RouteStep[] {
    const steps: RouteStep[] = [];
    sections.forEach(section => {
      section.steps.forEach(step => {
        steps.push(step);
      });
    });
    return steps;
  }

  _buildDirectionsForStart(step: RouteStep): Direction {
    const formattedInstructions = this._instructionBuilder.getFormattedInstructionForStart(step);
    const isFirstStep = true;
    const navRoutingDirectionModel = this._buildNavRoutingDirectionModel(formattedInstructions, step, isFirstStep);
    return this._buildWrldNavDirection(navRoutingDirectionModel);
  }

  _buildDirectionsForRouteStep(prevStep: RouteStep, step: RouteStep, nextStep: RouteStep, nextNextStep: RouteStep): Direction {

    const formattedInstructions = this._instructionBuilder.getFormattedInstructionForStep(prevStep, step, nextStep, nextNextStep);

    const isFirstStep = false;

    const navRoutingDirectionModel = this._buildNavRoutingDirectionModel(formattedInstructions, step, isFirstStep);

    return this._buildWrldNavDirection(navRoutingDirectionModel);
  }

  _buildWrldNavDirection(navRoutingDirectionModel: Direction): Direction {
    // WrldNavDirection
    return {
      name: navRoutingDirectionModel.name,
      icon: navRoutingDirectionModel.icon,
      instruction: navRoutingDirectionModel.instruction,
      nextInstruction: navRoutingDirectionModel.nextInstruction,
      path: navRoutingDirectionModel.path,
      indoorMapId: navRoutingDirectionModel.indoorMapId,
      indoorMapFloorId: navRoutingDirectionModel.indoorMapFloorId,
      nextIndoorMapFloorId: navRoutingDirectionModel.nextIndoorMapFloorId,
      isMultiFloor: navRoutingDirectionModel.isMultiFloor,
      isUsingPlaceHolder: navRoutingDirectionModel.isUsingPlaceHolder
    };
  }

  // see NavRoutingDirectionModel
  // https://github.com/wrld3d/wrld-example-app/blob/master/src/NavRouting/SdkModel/NavRoutingDirectionModel.h
  _makeDefaultNavRoutingDirectionModel(): Direction {
    return {
      name: "",
      icon: "",
      instruction: "",
      nextInstruction: "",
      path: [],
      distance: 0,
      isIndoors: false,
      indoorMapId: "",
      indoorMapFloorId: 0,
      indoorMapFloorName: "",
      isMultiFloor: false,
      nextIndoorMapFloorId: 0,
      isUsingPlaceHolder: false
    };
  }

  _getReadableFloorName(indoorMapId: wrld.Map.MapId, indoorMapFloorId: wrld.Map.MapFloorId): string {
    if (!this._config.getReadableFloorName) {
      return null;
    }

    const readableFloorName = this._config.getReadableFloorName(indoorMapId, indoorMapFloorId);
    return readableFloorName ? String(readableFloorName) : null;
  }

  _getReadableString(formatString: string, formatTokenToReplacement: FormatTokenToReplacement): string {
    let result = formatString;
    Object.keys(formatTokenToReplacement).forEach((key) => {
      result = result.replace(key, formatTokenToReplacement[key]);
    });
    return result;
  }

  _containsFormatToken(formatString: string, formatTokenToReplacement: FormatTokenToReplacement): boolean {
    return Object.values(formatTokenToReplacement).some((token) => {
      return formatString.includes(token);
    });
  }

  _buildNavRoutingDirectionModel(formattedInstructions: FormattedInstructions, step: RouteStep, isFirstStep: boolean): Direction {
    const hasLocationName = formattedInstructions.instructionLocation.length > 0;

    const instruction = hasLocationName
      ? formattedInstructions.instructionLocation
      : formattedInstructions.shortInstructionName;

    const nextInstruction = hasLocationName
      ? formattedInstructions.instructionDirection
      : "";

    const path = isFirstStep
      ? step.path.slice(0, 1)
      : step.path;

    const roundedStepDistance = isFirstStep
      ? 0
      : Math.ceil(step.distance);

    let navRoutingDirectionModel = null;
    if (step.isIndoors) {
      // C++ wrld-example-app has work-around in camera controller for MultiFloor step always having indoorMapId=0
      // https://github.com/wrld3d/wrld-example-app/blob/cf5a562362f317a50635f0c05c48c5db0f8e9df4/src/NavRouting/SdkModel/NavRoutingCameraController.cpp#L124
      // Instead, assign from next step's floor id here.
      const indoorMapFloorId = step.isMultiFloor
        ? formattedInstructions.nextIndoorMapFloorId
        : step.indoorMapFloorId;

      navRoutingDirectionModel = Object.assign(this._makeDefaultNavRoutingDirectionModel(), {
        name: formattedInstructions.shortInstructionName,
        icon: formattedInstructions.iconKey,
        instruction: instruction,
        nextInstruction: nextInstruction,
        path: path,
        distance: roundedStepDistance,
        isIndoors: step.isIndoors,
        indoorMapId: step.indoorMapId,
        indoorMapFloorId: indoorMapFloorId,
        isMultiFloor: step.isMultiFloor,
        nextIndoorMapFloorId: formattedInstructions.nextIndoorMapFloorId,
        isUsingPlaceHolder: true
      });
    }
    else {
      navRoutingDirectionModel = Object.assign(this._makeDefaultNavRoutingDirectionModel(), {
        name: formattedInstructions.shortInstructionName,
        icon: formattedInstructions.iconKey,
        instruction: instruction,
        nextInstruction: nextInstruction,
        path: path,
        distance: roundedStepDistance,
        isUsingPlaceHolder: true
      });
    }

    navRoutingDirectionModel = this._replaceFormatTokens(navRoutingDirectionModel);

    return navRoutingDirectionModel;
  }
}

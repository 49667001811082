import React from "react";

import SetupJourneyPanel from "../components/SetupJourneyPanel.jsx";
import TimeToDestinationPanel from "../components/TimeToDestinationPanel.jsx";
import DirectionsPanel from "../components/DirectionsPanel.jsx";

const defaultState = {
  startLocation: {},
  endLocation: {},
  isOpen: false,
  directions: [],
  routeDurationSeconds: 0,
  currentSelectedDirectionIndex: null
};

export default class NavWidgetContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = Object.assign({}, this.state, defaultState);

    this._onStartLocationFocussed = this._onStartLocationFocussed.bind(this);
    this._onEndLocationFocussed = this._onEndLocationFocussed.bind(this);
    this._onStartLocationClearClicked = this._onStartLocationClearClicked.bind(this);
    this._onEndLocationClearClicked = this._onEndLocationClearClicked.bind(this);
    this._onTopPanelBackClicked = this._onTopPanelBackClicked.bind(this);
    this._onSwitchFieldsClicked = this._onSwitchFieldsClicked.bind(this);
    this._onDirectionResultSelected = this._onDirectionResultSelected.bind(this);
  }

  resetControl() {
    this.setState(defaultState);
  }

  openControl() {
    if (this.state.isOpen) {
      return;
    }
    this.setState({isOpen:true});
  }

  closeControl() {
    if (!this.state.isOpen) {
      return;
    }
    this.setState({isOpen:false});
  }

  _isSetupJourneyPanelOpen() {
    return this.state.isOpen && !this.state.isShowingSearch;
  }

  _isSearchPanelOpen() {
    return this.state.isOpen && this.state.isShowingSearch;
  }

  swapJourneyLocations() {
    this.setState({
      startLocation: this.state.endLocation,
      endLocation: this.state.startLocation
    });
    this.props.onSwapJourneyLocationsCallback();
  }

  setDirections(directions) {
    if (Array.isArray(directions)) {
      this.setState({directions:directions});
    }
    else {
      this.clearDirections();
    }
  }

  clearDirections() {
    this.setState({
      directions:[],
      routeDurationSeconds: 0,
      currentSelectedDirectionIndex: null
    });
  }

  setRouteDuration(routeDurationSeconds) {
    this.setState({routeDurationSeconds:routeDurationSeconds});
  }

  clearLocations() {
    this._clearStartLocation();
    this._clearEndLocation();
  }

  _buildLocation(location) {
    return {
      name: location.name || "",
      latLng: L.latLng(location.latLng),
      isIndoors: location.isIndoors || false,
      indoorMapId: location.indoorMapId || "",
      indoorMapFloorId: location.indoorMapFloorId || 0
    };
  }

  setStartLocation(location) {
    this.setState({
      startLocation: this._buildLocation(location)
    });
  }

  setEndLocation(location) {
    this.setState({
      endLocation: this._buildLocation(location)
    });
  }

  _onStartLocationFocussed() {
    this.props.onStartLocationFocussedCallback();
  }

  _onEndLocationFocussed() {
    this.props.onEndLocationFocussedCallback();
  }

  _onStartLocationClearClicked() {
    this._clearStartLocation();
  }

  _onEndLocationClearClicked() {
    this._clearEndLocation();
  }

  _onTopPanelBackClicked() {
    this.closeControl();
    this.props.onBackSelectedCallback();
  }

  _onSwitchFieldsClicked() {
    this.swapJourneyLocations();
  }

  _onDirectionResultSelected(direction, rowIndex) {
    this.setState({
      currentSelectedDirectionIndex: rowIndex
    });
    this.props.onDirectionResultSelectedCallback({direction:direction, rowIndex:rowIndex});
  }

  _clearStartLocation() {
    this.setState({
      startLocation: {}
    });
    this.props.onStartLocationClearedCallback();
  }

  _clearEndLocation() {
    this.setState({
      endLocation: {}
    });
    this.props.onEndLocationClearedCallback();
  }

  _getSetupJourneyPanel() {
    const {
      startLocation,
      endLocation
    } = this.state;

    const isSetupJourneyPanelOpen = this._isSetupJourneyPanelOpen();
    const topPanelClassName = "top-panel " + (isSetupJourneyPanelOpen ? "expanded" : "collapsed");

    return (
      <div className={topPanelClassName}>
        <SetupJourneyPanel
          startLocationName={startLocation.name || ""}
          endLocationName={endLocation.name || ""}
          onStartLocationFocus={this._onStartLocationFocussed}
          onEndLocationFocus={this._onEndLocationFocussed}
          onStartLocationClearClick={this._onStartLocationClearClicked}
          onEndLocationClearClick={this._onEndLocationClearClicked}
          onTopPanelBackClick={this._onTopPanelBackClicked}
          onSwitchFieldsClick={this._onSwitchFieldsClicked}
          isContentVisible={isSetupJourneyPanelOpen}
        />
      </div>
    );
  }

  _hasDirections() {
    const {
      directions
    } = this.state;

    return (Array.isArray(directions) && directions.length);
  }

  _getBottomPanel() {
    const {
      routeDurationSeconds,
      directions,
      currentSelectedDirectionIndex
    } = this.state;

    const {
      config
    } = this.props;

    const navWidgetBaseUrl = config.get("navWidgetBaseUrl");

    const hasDirections = this._hasDirections();

    const bottomPanelClassName = "bottom-panel " + (hasDirections ? "expanded" : "collapsed");

    const routeDurationMinutesComponent = Math.floor(routeDurationSeconds / 60);
    const routeDurationSecondsComponent = Math.round(routeDurationSeconds - (routeDurationMinutesComponent * 60));

    return (
      <div className={bottomPanelClassName}>
        <TimeToDestinationPanel
          isContentVisible={hasDirections}
          routeDurationMinutes={routeDurationMinutesComponent}
          routeDurationSeconds={routeDurationSecondsComponent}
        />

        <DirectionsPanel
          directions={directions}
          onResultSelect={this._onDirectionResultSelected}
          highlightIndex={currentSelectedDirectionIndex}
          baseUrl={navWidgetBaseUrl}
        />
      </div>
    );
  }

  render() {
    const isSetupJourneyPanelOpen = this._isSetupJourneyPanelOpen();
    const containerClassName = "wrld-navwidget-container " + (isSetupJourneyPanelOpen ? "expanded" : "collapsed");
    return (
      <div className={containerClassName}>
        {this._getSetupJourneyPanel()}
        {this._getBottomPanel()}
      </div>
    );
  }
}
